<template>
  <section>
    <slideout-panel></slideout-panel>
    <sidebar />
    <div class="page-container">
      <app-header />
      <main class="main-content bgc-grey-100">
        <div :id="$i18n.locale == 'ar' ? 'mainContent' : ''">
          <div class="container-fluid">
            <router-view />
          </div>
        </div>
      </main>
      <app-footer />
    </div>
  </section>
</template>

<script>
export default {
  
};
</script>

<style lang="scss" scoped>
#mainContent {
  text-align: right;
  direction: rtl;
}
.header {
  z-index:98;
}
.sidebar {
  z-index:99;
}
</style>